import axios, {
    AxiosResponse
} from 'axios';
import {
    DeploymentRequestDto,
    WrappedResponse,
    CreateDeploymentRequestDto,
    DestroyDeploymentRequestDto,
    GetDeploymentRequestLogsResponseDto,
    GetDeploymentRequestsResponseDto,
    ResetDatabaseInputDto,
    RestartAppInputDto,
    RunBuildResponseDto,
    RunBuildRequestDto
} from './models'
export default class DeploymentRequestsApiService {
    public static create(body: CreateDeploymentRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < DeploymentRequestDto >>> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/Create`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static destroy(body: DestroyDeploymentRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < DeploymentRequestDto >>> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/Destroy`,
            method: 'delete',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static getLogs(params ? : {
        deploymentRequestId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < GetDeploymentRequestLogsResponseDto >>> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/GetLogs`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static getAll(params ? : {
        filter ? : string,
        includeDestroyed ? : boolean,
        projectId ? : number
    }, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < GetDeploymentRequestsResponseDto >>> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/GetAll`,
            method: 'get',
            params: params,
            clientConfig: clientConfig
        })
    }
    public static resetDatabase(body: ResetDatabaseInputDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/ResetDatabase`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static restartApp(body: RestartAppInputDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < any >> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/RestartApp`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
    public static runBuild(body: RunBuildRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < RunBuildResponseDto >>> {
        return axios.request( < any > {
            url: `/api/DeploymentRequests/RunBuild`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}
<script setup lang="ts">
import { computed, ref, onUnmounted, onMounted } from "vue";
import debounce from "lodash/debounce";
import DeploymentRequestsApiService from "@/api/DeploymentRequestsApiService";
import DeploymentStatusComponent from "@/components/DeploymentStatusComponent.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import DeploymentButtonsComponent from "@/components/DeploymentButtonsComponent.vue";
import DeploymentRequestTableRow from "@/components/DeploymentRequestTableRow.vue";
import DeploymentLogsModal from "@/components/DeploymentLogsModal.vue";
import { HubConnectionState } from "@microsoft/signalr";
import {
  AutomationTestJobDto,
  AutomationTestJobStatus,
  BuildInfoDto,
  DatabaseTemplateDto,
  DeploymentRequestDto,
  GitBranchDto,
  ProjectDto,
} from "@/api/models";

import { formatBranchName } from "@/common/utils";
import DatabaseTemplatesApiService from "@/api/DatabaseTemplatesApiService";
import AutomationTestJobsApiService from "@/api/AutomationTestJobsApiService";
import auth from "@/services/Auth";
import { DeploymentRequestsHubConnection } from "@/services/DeploymentRequestsHubConnection";
import ProjectsApiService from "@/api/ProjectsApiService";
const isBusy = ref(false);
const includeDestroyed = ref(false);
const searchFilter = ref("");
const logModalDeploymentRequestId = ref<number>(0);
const deploymentRequests = ref<DeploymentRequestDto[]>([]);
const branches = ref<GitBranchDto[]>([]);
const selectedDatabaseTemplate = ref<DatabaseTemplateDto>(null);
const databaseTemplates = ref<DatabaseTemplateDto[]>([]);
const selectedProject = ref<ProjectDto>(null);
const projects = ref<ProjectDto[]>([]);
const automationTestJobs = ref<AutomationTestJobDto[]>([]);
const isSignalRConnected = ref<boolean>(false);
const isSignalRInitialized = ref<boolean>(false);
const isRequestingBuild = ref<boolean>(false);
const showActiveAutomationTestJobModal = ref<boolean>(false);
const showAutomationTestRequested = ref<boolean>(false);
const pipelineUrl = ref<string>(null);
const deploymentRequestsHubConnection = new DeploymentRequestsHubConnection();

const showConnectionError = computed(() => {
  return isSignalRInitialized.value && !isSignalRConnected.value;
});
const onSearchFilterChanged = debounce(async () => {
  await executeSearch();
}, 500);

const yourDeploymentRequests = computed(() => {
  return deploymentRequests.value.filter(
    (d) => d.requestedById == auth.account.localAccountId
  );
});
const showLogModal = computed(() => {
  return logModalDeploymentRequest.value != null;
});
const hasSearchFilter = computed(() => {
  return searchFilter.value;
});
const logModalDeploymentRequest = computed(() => {
  return deploymentRequests?.value?.find(
    (d) => d.deploymentRequestId == logModalDeploymentRequestId.value
  );
});

const showPipelineModal = computed(() => {
  return !!pipelineUrl.value;
});

onMounted(async () => {
  deploymentRequestsHubConnection.addConnectionChangedListener(
    onConnectionChanged
  );
  await deploymentRequestsHubConnection.init();
  isSignalRInitialized.value = true;

  deploymentRequestsHubConnection.addDeploymentRequestInsertedListener(
    onDeploymentRequestInserted
  );

  deploymentRequestsHubConnection.addDeploymentRequestUpdatedListener(
    onDeploymentRequestUpdated
  );

  deploymentRequestsHubConnection.addAutomationTestJobInsertedListener(
    onAutomationTestJobInserted
  );

  deploymentRequestsHubConnection.addAutomationTestJobUpdatedListener(
    onAutomationTestJobUpdated
  );

  deploymentRequestsHubConnection.addBuildInfoInsertedListener(
    onBuildInfoInserted
  );

  deploymentRequestsHubConnection.addBuildInfoUpdatedListener(
    onBuildInfoUpdated
  );

  databaseTemplates.value = (
    await DatabaseTemplatesApiService.getAll()
  ).data.result.databaseTemplates;

  selectedDatabaseTemplate.value = databaseTemplates.value[0];

  automationTestJobs.value = (
    await AutomationTestJobsApiService.getAll()
  ).data.result.automationTestJobs;

  projects.value = (await ProjectsApiService.getAll()).data.result.projects;

  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has("projectId")) {
    selectedProject.value = projects.value.find(
      (d) => d.projectId === +searchParams.get("projectId")
    );
  }
  if (!selectedProject.value) {
    selectedProject.value = projects.value[0];
  }
  executeSearch();
});

onUnmounted(() => {
  deploymentRequestsHubConnection.removeDeploymentRequestInsertedListener(
    onDeploymentRequestInserted
  );
  deploymentRequestsHubConnection.removeDeploymentRequestUpdatedListener(
    onDeploymentRequestUpdated
  );

  deploymentRequestsHubConnection.removeAutomationTestJobInsertedListener(
    onAutomationTestJobInserted
  );

  deploymentRequestsHubConnection.removeAutomationTestJobUpdatedListener(
    onAutomationTestJobUpdated
  );

  deploymentRequestsHubConnection.removeBuildInfoInsertedListener(
    onBuildInfoInserted
  );

  deploymentRequestsHubConnection.removeBuildInfoUpdatedListener(
    onBuildInfoUpdated
  );
  deploymentRequestsHubConnection.removeConnectionChangedListener(
    onConnectionChanged
  );
});

function onConnectionChanged(state: HubConnectionState): void {
  isSignalRConnected.value = state == HubConnectionState.Connected;
}

function onDeploymentRequestInserted(
  deploymentRequest: DeploymentRequestDto
): void {
  const existingIndex = deploymentRequests.value.findIndex(
    (d) => d.deploymentRequestId == deploymentRequest.deploymentRequestId
  );
  if (existingIndex >= 0) {
    return;
  }
  deploymentRequests.value.push(deploymentRequest);
}

function onDeploymentRequestUpdated(
  deploymentRequest: DeploymentRequestDto
): void {
  const existingIndex = deploymentRequests.value.findIndex(
    (d) => d.deploymentRequestId == deploymentRequest.deploymentRequestId
  );
  if (existingIndex < 0) {
    return;
  }
  deploymentRequests.value[existingIndex] = deploymentRequest;
}

function onAutomationTestJobInserted(
  automationTestJob: AutomationTestJobDto
): void {
  const existingIndex = automationTestJobs.value.findIndex(
    (d) => d.automationTestJobId == automationTestJob.automationTestJobId
  );
  if (existingIndex >= 0) {
    return;
  }
  automationTestJobs.value.push(automationTestJob);
}

function onAutomationTestJobUpdated(
  automationTestJob: AutomationTestJobDto
): void {
  const existingIndex = automationTestJobs.value.findIndex(
    (d) => d.automationTestJobId == automationTestJob.automationTestJobId
  );
  if (existingIndex < 0) {
    return;
  }
  automationTestJobs.value[existingIndex] = automationTestJob;
}

function onBuildInfoInserted(buildInfo: BuildInfoDto): void {
  const deploymentRequest = deploymentRequests.value.find(
    (d) => d.branchName == buildInfo.branchName
  );
  deploymentRequest.buildInfo = buildInfo;
}

function onBuildInfoUpdated(buildInfo: BuildInfoDto): void {
  const deploymentRequest = deploymentRequests.value.find(
    (d) => d.branchName == buildInfo.branchName
  );
  deploymentRequest.buildInfo = buildInfo;
}

async function executeSearch(): Promise<void> {
  try {
    const result = await DeploymentRequestsApiService.getAll({
      filter: searchFilter.value,
      includeDestroyed: includeDestroyed.value,
      projectId: selectedProject.value.projectId,
    });
    const sortedItems = result.data.result.requests.sort((a, b) => {
      const aDate = new Date(a.requestedDate).getTime();
      const bDate = new Date(b.requestedDate).getTime();
      if (aDate == bDate) {
        return 0;
      }
      return aDate < bDate ? 1 : -1;
    });

    sortedItems.forEach((deploymentRequest) => {
      const index = deploymentRequests.value.findIndex(
        (item) =>
          item.deploymentRequestId == deploymentRequest.deploymentRequestId
      );
      if (index === -1) {
        deploymentRequests.value.push(deploymentRequest);
      }
    });
    deploymentRequests.value = result.data.result.requests;
    branches.value = result.data.result.branches;
  } finally {
  }
}

async function runBuild(
  deploymentRequest: DeploymentRequestDto
): Promise<void> {
  try {
    isRequestingBuild.value = true;

    const response = await DeploymentRequestsApiService.runBuild({
      branch: deploymentRequest.branchName,
      projectId: selectedProject.value.projectId,
    });

    pipelineUrl.value = response.data.result.buildUrl;
  } finally {
    isRequestingBuild.value = false;
  }
}

async function restartWebApp(
  deploymentRequest: DeploymentRequestDto
): Promise<void> {
  await DeploymentRequestsApiService.restartApp({
    deploymentRequestId: deploymentRequest.deploymentRequestId,
  });
}

function findActiveAutomationTestJob(
  deploymentRequest: DeploymentRequestDto
): AutomationTestJobDto {
  const jobs = automationTestJobs.value.filter(
    (d) => d.deploymentRequestId == deploymentRequest.deploymentRequestId
  );
  return jobs.find((d) =>
    [
      AutomationTestJobStatus.Queued,
      AutomationTestJobStatus.WaitingForDeployment,
      AutomationTestJobStatus.RunningTests,
      AutomationTestJobStatus.DestroyingDeployment,
    ].includes(d.status)
  );
}
async function runAutomationTests(
  deploymentRequest: DeploymentRequestDto
): Promise<void> {
  try {
    const existing = findActiveAutomationTestJob(deploymentRequest);
    if (existing) {
      showActiveAutomationTestJobModal.value = true;
      return;
    }
    isRequestingBuild.value = true;

    const response = await AutomationTestJobsApiService.runAutomationTests({
      deploymentRequestId: deploymentRequest.deploymentRequestId,
    });
    showAutomationTestRequested.value = true;
  } finally {
    isRequestingBuild.value = false;
  }
}

async function resetDatabase(
  deploymentRequest: DeploymentRequestDto
): Promise<void> {
  await DeploymentRequestsApiService.resetDatabase({
    deploymentRequestId: deploymentRequest.deploymentRequestId,
  });
}
async function destroyDeployment(
  deploymentRequest: DeploymentRequestDto
): Promise<void> {
  isBusy.value = true;
  try {
    const result = await DeploymentRequestsApiService.destroy({
      deploymentRequestId: deploymentRequest.deploymentRequestId,
    });

    const index = deploymentRequests.value.findIndex(
      (d) => d.deploymentRequestId == deploymentRequest.deploymentRequestId
    );
    deploymentRequests.value[index] = result.data.result;
  } finally {
    isBusy.value = false;
  }
}

function confirmOpenPipelineUrl(): void {
  const aElement = document.createElement("a");
  aElement.setAttribute("href", pipelineUrl.value);
  aElement.setAttribute("target", "_blank");
  document.body.append(aElement);
  aElement.click();
  aElement.remove();
  pipelineUrl.value = null;
}

function cancelOpenPipelineUrl(): void {
  pipelineUrl.value = null;
}

async function requestDeployment(branchName: string): Promise<void> {
  isBusy.value = true;
  searchFilter.value = null;

  try {
    const result = await DeploymentRequestsApiService.create({
      branchName: branchName,
      databaseTemplateId: selectedDatabaseTemplate.value.databaseTemplateId,
      projectId: selectedProject.value.projectId,
    });
    deploymentRequests.value.unshift(result.data.result);
    await executeSearch();
  } finally {
    isBusy.value = false;
  }
}

function getExistingDeployment(branchName: string): DeploymentRequestDto {
  return deploymentRequests.value.find(
    (d) => d.branchName.toLowerCase() == branchName.toLowerCase()
  );
}

function hasExistingDeployment(branchName: string): boolean {
  return getExistingDeployment(branchName) != null;
}

function highlightText(text: string, searchQuery: string) {
  if (!searchQuery) return text;
  const highlightedText = text.replace(
    new RegExp(searchQuery, "gi"),
    (match) => `<mark class="highlight">${match}</mark>`
  );
  return highlightedText;
}

function openLogsModal(deploymentRequest: DeploymentRequestDto): void {
  logModalDeploymentRequestId.value = deploymentRequest.deploymentRequestId;
}

const highlightedBranches = computed(() => {
  if (!branches.value) {
    return [];
  }
  return branches.value.map((branch) => ({
    ...branch,
    highlightedName: highlightText(
      formatBranchName(branch.name),
      searchFilter.value
    ),
  }));
});
</script>

<template>
  <DeploymentLogsModal
    v-if="showLogModal"
    :deploymentRequest="logModalDeploymentRequest"
    @close="logModalDeploymentRequestId = null"
  />

  <ConfirmModal
    @confirm="confirmOpenPipelineUrl"
    @cancel="cancelOpenPipelineUrl"
    message="Would you like to open the pipeline in another tab?"
    :is-visible="showPipelineModal"
  />

  <ConfirmModal
    message="You have an active automation test job. Please wait for this to finish before starting a new one"
    title="Active Job"
    :is-visible="showActiveAutomationTestJobModal"
    :hide-cancel="true"
    confirm-button-text="Ok"
    @confirm="showActiveAutomationTestJobModal = false"
    @cancel="showActiveAutomationTestJobModal = false"
  >
  </ConfirmModal>

  <ConfirmModal
    message="A request has be placed to run the automation tests, please wait."
    title="Request made"
    :is-visible="showAutomationTestRequested"
    :hide-cancel="true"
    confirm-button-text="Ok"
    @confirm="showAutomationTestRequested = false"
    @cancel="showAutomationTestRequested = false"
  >
  </ConfirmModal>

  <div
    class="connection-lost w-11/12 bg-red-300 text-gray-600 px-5 py-1 m-auto border-x border-b border-red-400 text-xs text-center animate"
    :class="{ active: showConnectionError }"
  >
    Connection lost! Live updates are unavailable
  </div>

  <main class="container mx-auto px-4 py-8">
    <h1 class="text-lg">
      Deployment Manager
      <span class="text-sm text-gray-300">{{
        selectedProject?.displayName
      }}</span>
    </h1>

    <div class="flex justify-center mt-5">
      <input
        placeholder="Search for branch..."
        v-model="searchFilter"
        @input="onSearchFilterChanged"
        class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
      />
      <div class="ml-5" v-if="searchFilter && databaseTemplates.length > 0">
        <select
          v-model="selectedDatabaseTemplate"
          class="p-2 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option
            v-for="databaseTemplate in databaseTemplates"
            :key="databaseTemplate.databaseTemplateId"
            :value="databaseTemplate"
          >
            {{ databaseTemplate.displayName }}
          </option>
        </select>
      </div>
    </div>
    <div class="flex flex-col mt-8">
      <div v-if="!hasSearchFilter">
        <label
          for="toggle"
          class="inline-flex relative items-center cursor-pointer"
        >
          <!-- Input -->
          <input
            type="checkbox"
            id="toggle"
            class="sr-only peer"
            v-model="includeDestroyed"
            @input="onSearchFilterChanged"
          />
          <!-- Line -->
          <div
            class="w-11 h-6 bg-gray-200 rounded-full peer-checked:bg-blue-600 dark:bg-gray-700 peer-checked:dark:bg-blue-600 transition duration-200 ease-in-out"
          ></div>
          <!-- Dot -->
          <div
            class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition transform peer-checked:translate-x-5"
          ></div>
          <span class="ml-2">Include Destroyed</span>
        </label>
        <h1 class="text-2xl mt-10 mb-5">Your Deployments</h1>
        <table
          v-if="yourDeploymentRequests.length > 0"
          class="min-w-full leading-normal shadow-md rounded-lg overflow-hidden"
        >
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Branch name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Requested
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Deployed
              </th>

              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Destroy Requested
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Destroyed
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Build Info
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody>
            <DeploymentRequestTableRow
              v-for="deploymentRequest in yourDeploymentRequests.filter(
                (d) => d.parentDeploymentRequestId == null
              )"
              :key="deploymentRequest.deploymentRequestId"
              :deployment-request="deploymentRequest"
              :is-busy="isBusy"
              :automation-test-jobs="automationTestJobs"
              @open-logs-modal="openLogsModal"
              @run-build="runBuild"
              @restart-web-app="restartWebApp"
              @run-automation-tests="runAutomationTests"
              @reset-database="resetDatabase"
              @destroy="destroyDeployment"
            />
          </tbody>
        </table>
        <div class="text-center" v-else>Nothing to see here 👀</div>

        <h1 class="text-2xl mt-10 mb-5">All Deployments</h1>
        <table
          class="min-w-full leading-normal shadow-md rounded-lg overflow-hidden"
        >
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Branch name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Requested
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Deployed
              </th>

              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Destroy Requested
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Destroyed
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Build Info
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>
          <tbody>
            <DeploymentRequestTableRow
              v-for="deploymentRequest in deploymentRequests.filter(
                (d) => d.parentDeploymentRequestId == null
              )"
              :key="deploymentRequest.deploymentRequestId"
              :deployment-request="deploymentRequest"
              :is-busy="isBusy"
              :automation-test-jobs="automationTestJobs"
              @open-logs-modal="openLogsModal"
              @run-build="runBuild"
              @restart-web-app="restartWebApp"
              @run-automation-tests="runAutomationTests"
              @reset-database="resetDatabase"
              @destroy="destroyDeployment"
            />
          </tbody>
        </table>
      </div>
      <div v-else>
        <table
          class="min-w-full leading-normal shadow-md rounded-lg overflow-hidden"
        >
          <thead>
            <tr>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Branch name
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
              ></th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="branch in highlightedBranches">
              <td
                class="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                v-html="branch.highlightedName"
              ></td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <DeploymentStatusComponent
                  v-if="hasExistingDeployment(branch.name)"
                  :deploymentRequest="getExistingDeployment(branch.name)"
                  :show-text="true"
                />
                <div v-else>
                  <button
                    :disabled="isBusy"
                    @click="requestDeployment(branch.name)"
                    class="ml-5 h-9 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-blue-300 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                  >
                    Request Deployment
                  </button>
                </div>
              </td>
              <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <DeploymentButtonsComponent
                  v-if="hasExistingDeployment(branch.name)"
                  :deployment-request="getExistingDeployment(branch.name)"
                  :is-busy="isBusy"
                  @run-build="runBuild"
                  @restart-web-app="restartWebApp"
                  @run-automation-tests="runAutomationTests"
                  @reset-database="resetDatabase"
                  @destroy="destroyDeployment"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<style>
.highlight {
  background-color: yellow;
}

.connection-lost {
  transition: opacity 500ms ease;
  opacity: 0;
}
.connection-lost.active {
  opacity: 1;
}
</style>

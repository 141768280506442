import {
  AutomationTestJobDto,
  BuildInfoDto,
  DeploymentRequestDto,
} from "@/api/models";
import SignalRHubConnection from "./SignalRHubConnection";

export class DeploymentRequestsHubConnection extends SignalRHubConnection {
  constructor() {
    super("deployment-requests");
  }

  private _deploymentRequestUpdatedMethodName: string =
    "deployment-request-updated";
  private _deploymentRequestInsertedMethodName: string =
    "deployment-request-inserted";
  private _automationTestJobUpdatedMethodName: string =
    "automation-test-job-updated";
  private _automationTestJobInsertedMethodName: string =
    "automation-test-job-inserted";
  private _buildInfoUpdatedMethodName: string = "buildinfo-updated";
  private _buildInfoInsertedMethodName: string = "buildinfo-inserted";

  public addBuildInfoInsertedListener(
    callBack: (deploymentRequest: BuildInfoDto) => void
  ): void {
    this.connection.on(this._buildInfoInsertedMethodName, callBack);
  }

  public removeBuildInfoInsertedListener(
    callBack: (deploymentRequest: BuildInfoDto) => void
  ): void {
    this.connection.off(this._buildInfoInsertedMethodName, callBack);
  }

  public addBuildInfoUpdatedListener(
    callBack: (deploymentRequest: BuildInfoDto) => void
  ): void {
    this.connection.on(this._buildInfoUpdatedMethodName, callBack);
  }

  public removeBuildInfoUpdatedListener(
    callBack: (deploymentRequest: BuildInfoDto) => void
  ): void {
    this.connection.off(this._buildInfoUpdatedMethodName, callBack);
  }

  public addDeploymentRequestInsertedListener(
    callBack: (deploymentRequest: DeploymentRequestDto) => void
  ): void {
    this.connection.on(this._deploymentRequestInsertedMethodName, callBack);
  }

  public removeDeploymentRequestInsertedListener(
    callBack: (deploymentRequest: DeploymentRequestDto) => void
  ): void {
    this.connection.off(this._deploymentRequestInsertedMethodName, callBack);
  }

  public addDeploymentRequestUpdatedListener(
    callBack: (deploymentRequest: DeploymentRequestDto) => void
  ): void {
    this.connection.on(this._deploymentRequestUpdatedMethodName, callBack);
  }

  public removeDeploymentRequestUpdatedListener(
    callBack: (deploymentRequest: DeploymentRequestDto) => void
  ): void {
    this.connection.off(this._deploymentRequestUpdatedMethodName, callBack);
  }

  public addAutomationTestJobInsertedListener(
    callBack: (automationTestJob: AutomationTestJobDto) => void
  ): void {
    this.connection.on(this._automationTestJobInsertedMethodName, callBack);
  }

  public removeAutomationTestJobInsertedListener(
    callBack: (automationTestJob: AutomationTestJobDto) => void
  ): void {
    this.connection.off(this._automationTestJobInsertedMethodName, callBack);
  }

  public addAutomationTestJobUpdatedListener(
    callBack: (automationTestJob: AutomationTestJobDto) => void
  ): void {
    this.connection.on(this._automationTestJobUpdatedMethodName, callBack);
  }

  public removeAutomationTestJobUpdatedListener(
    callBack: (automationTestJob: AutomationTestJobDto) => void
  ): void {
    this.connection.off(this._automationTestJobUpdatedMethodName, callBack);
  }
}

import axios, {
    AxiosResponse
} from 'axios';
import {
    GetProjectsResponseDto,
    WrappedResponse
} from './models'
export default class ProjectsApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < GetProjectsResponseDto >>> {
        return axios.request( < any > {
            url: `/api/Projects/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
}
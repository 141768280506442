import axios, {
    AxiosResponse
} from 'axios';
import {
    GetAutomationTestJobsResponseDto,
    WrappedResponse,
    RunAutomationTestsResponseDto,
    RunAutomationTestsRequestDto
} from './models'
export default class AutomationTestJobsApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < GetAutomationTestJobsResponseDto >>> {
        return axios.request( < any > {
            url: `/api/AutomationTestJobs/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
    public static runAutomationTests(body: RunAutomationTestsRequestDto, clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < RunAutomationTestsResponseDto >>> {
        return axios.request( < any > {
            url: `/api/AutomationTestJobs/RunAutomationTests`,
            method: 'post',
            data: body,
            clientConfig: clientConfig
        })
    }
}
class AutomationTestJobDto {
    constructor(public automationTestJobId: number, public deploymentRequestId: number, public parentDeploymentRequestId: number, public buildId: number, public requestedDate: string, public status ? : AutomationTestJobStatus, public buildUrl ? : string, public errorMessage ? : string, public requestedBy ? : string) {}
}
enum AutomationTestJobStatus {
    Queued = 0, WaitingForDeployment = 1, RunningTests = 2, DestroyingDeployment = 3, Complete = 4, Error = 5
}
class BuildInfoDto {
    constructor(public buildId: number, public status ? : string, public result ? : string, public branchName ? : string, public startTime ? : string, public finishTime ? : string, public buildUrl ? : string) {}
}
class CreateDeploymentRequestDto {
    constructor(public projectId: number, public databaseTemplateId: number, public branchName ? : string) {}
}
class DatabaseTemplateDto {
    constructor(public databaseTemplateId: number, public displayName ? : string) {}
}
class DeploymentRequestDto {
    constructor(public deploymentRequestId: number, public requestedById: string, public requestedDate: string, public branchName ? : string, public branchIdentifier ? : string, public dockerTag ? : string, public requestedBy ? : string, public destroyedBy ? : string, public status ? : DeploymentStatus, public deployedDate ? : string, public destroyedDate ? : string, public destroyRequestedDate ? : string, public errorMessage ? : string, public deploymentUrl ? : string, public parentDeploymentRequestId ? : number, public buildInfo ? : BuildInfoDto, public currentBuild ? : string) {}
}
class DeploymentRequestLogDto {
    constructor(public deploymentRequestLogId: number, public deploymentRequestId: number, public timestamp: string, public message ? : string) {}
}
enum DeploymentStatus {
    PendingDeployment = 0, Deploying = 1, Deployed = 2, PendingDestroy = 3, Destroying = 4, Destroyed = 5, Error = 6, PendingDatabaseReset = 7, DatabaseResetting = 8
}
class DestroyDeploymentRequestDto {
    constructor(public deploymentRequestId: number) {}
}
class GetAutomationTestJobsResponseDto {
    constructor(public automationTestJobs ? : AutomationTestJobDto[]) {}
}
class GetDatabaseTemplatesResponseDto {
    constructor(public databaseTemplates ? : DatabaseTemplateDto[]) {}
}
class GetDeploymentRequestLogsResponseDto {
    constructor(public logs ? : DeploymentRequestLogDto[]) {}
}
class GetDeploymentRequestsResponseDto {
    constructor(public requests ? : DeploymentRequestDto[], public branches ? : GitBranchDto[]) {}
}
class GetProjectsResponseDto {
    constructor(public projects ? : ProjectDto[]) {}
}
class GitBranchDto {
    constructor(public name ? : string) {}
}
class ProjectDto {
    constructor(public projectId: number, public displayName ? : string) {}
}
class ResetDatabaseInputDto {
    constructor(public deploymentRequestId: number) {}
}
class RestartAppInputDto {
    constructor(public deploymentRequestId: number) {}
}
class RunAutomationTestsRequestDto {
    constructor(public deploymentRequestId: number) {}
}
class RunAutomationTestsResponseDto {
    constructor(public existing: boolean, public buildUrl ? : string) {}
}
class RunBuildRequestDto {
    constructor(public projectId: number, public branch ? : string) {}
}
class RunBuildResponseDto {
    constructor(public existing: boolean, public buildUrl ? : string) {}
}
class WrappedResponse < T > {
    public result: T;public validationErrors: any;
}
export {
    WrappedResponse,
    AutomationTestJobDto,
    AutomationTestJobStatus,
    BuildInfoDto,
    CreateDeploymentRequestDto,
    DatabaseTemplateDto,
    DeploymentRequestDto,
    DeploymentRequestLogDto,
    DeploymentStatus,
    DestroyDeploymentRequestDto,
    GetAutomationTestJobsResponseDto,
    GetDatabaseTemplatesResponseDto,
    GetDeploymentRequestLogsResponseDto,
    GetDeploymentRequestsResponseDto,
    GetProjectsResponseDto,
    GitBranchDto,
    ProjectDto,
    ResetDatabaseInputDto,
    RestartAppInputDto,
    RunAutomationTestsRequestDto,
    RunAutomationTestsResponseDto,
    RunBuildRequestDto,
    RunBuildResponseDto
}
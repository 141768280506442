<script setup lang="ts">
import DeploymentRequestsApiService from "@/api/DeploymentRequestsApiService";
import { DeploymentRequestDto, DeploymentRequestLogDto } from "@/api/models";
import { format } from "date-fns";
import { ref, onMounted, onBeforeUnmount, toRefs, computed } from "vue";
import DeploymentStatusComponent from "./DeploymentStatusComponent.vue";
import { DeploymentRequestLogsHubConnection } from "@/services/DeploymentRequestLogsHubConnection";

const emit = defineEmits(["close"]);
const props = defineProps<{ deploymentRequest: DeploymentRequestDto }>();
const deploymentRequestLogsHubConnection =
  new DeploymentRequestLogsHubConnection();
const { deploymentRequest } = toRefs(props);
let isUnmounted = false;
const logs = ref<DeploymentRequestLogDto[]>([]);

const error = ref<string | null>(null);
const logsSorted = computed(() => {
  return logs.value.sort((a, b) => {
    const aDate = new Date(a.timestamp).getTime();
    const bDate = new Date(b.timestamp).getTime();
    if (aDate == bDate) {
      return 0;
    }

    return aDate > bDate ? -1 : 1;
  });
});

onMounted(async () => {
  // connect to signalR before getting the initial logs
  await deploymentRequestLogsHubConnection.init();
  deploymentRequestLogsHubConnection.subscribeToDeploymentRequestLogs(
    deploymentRequest.value.deploymentRequestId,
    onDeploymentRequestLog
  );

  //load the initial logs
  const initialLogs = await DeploymentRequestsApiService.getLogs({
    deploymentRequestId: deploymentRequest.value.deploymentRequestId,
  });
  logs.value.push(...initialLogs.data.result.logs);
});

onBeforeUnmount(() => {
  isUnmounted = true;
  deploymentRequestLogsHubConnection.unsubscribeToDeploymentRequestLogs(
    deploymentRequest.value.deploymentRequestId,
    onDeploymentRequestLog
  );
});

async function onDeploymentRequestLog(
  log: DeploymentRequestLogDto
): Promise<void> {
  const logExists = logs.value.find(
    (d) => d.deploymentRequestLogId == log.deploymentRequestLogId
  );
  if (logExists) {
    return;
  }
  logs.value.push(log);
}

function formatDate(date: string): string {
  return format(new Date(date), "eee eo LLL HH:mm:ss.SSS");
}
function closeModal() {
  emit("close");
}
</script>

<template>
  <div
    class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
  >
    <div
      class="w-3/5 h-4/5 bg-white rounded-md shadow-md p-5 overflow-y-auto relative z-50"
    >
      <span
        class="text-sml select-none cursor-pointer float-right text-gray-700"
        @click="closeModal"
        >x</span
      >
      <h1 class="text-xl mb-3">Deployment Logs</h1>
      <div class="flex">
        <span class="text-gray-600">{{
          props.deploymentRequest.branchName
        }}</span>
        <DeploymentStatusComponent
          class="ml-5"
          :deployment-request="props.deploymentRequest"
          :show-text="true"
        />
      </div>
      <hr class="mt-3 mb-2" />
      <ul class="list-none p-0 m-0">
        <li
          v-for="log in logsSorted"
          :key="log.deploymentRequestLogId"
          class="mb-2 p-2 border-b border-gray-300"
        >
          <span class="font-bold text-gray-700">{{
            formatDate(log.timestamp)
          }}</span>
          <span class="ml-5 text-gray-600">{{ log.message }}</span>
        </li>
      </ul>
      <p v-if="error" class="text-red-600">{{ error }}</p>
    </div>
  </div>
</template>

<style scoped></style>
import { DeploymentRequestLogsHubConnection } from
"@/services/DeploymentRequestLogsHubConnection";

<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";

const props = defineProps({
  isVisible: {
    type: Boolean,
    default: false,
  },
  title: String,
  message: String,
  modalType: String,
  isValid: {
    type: Boolean,
    default: true,
  },
  hideCancel: Boolean,
  confirmButtonText: {
    type: String,
    default: "Confirm",
  },
  cancelButtonText: {
    type: String,
    default: "Cancel",
  },
});
const emit = defineEmits(["confirm", "cancel"]);
const visible = ref(props.isVisible);

const confirmButtonClass = computed(() => {
  if (props.modalType == "Error") {
    return "bg-red-500  hover:bg-red-600";
  } else if (props.modalType == "Warning") {
    return "bg-orange-500  hover:bg-orange-600";
  } else {
    return "bg-green-500  hover:bg-green-600";
  }
});

// Watch for external changes to isVisible
watchEffect(() => {
  visible.value = props.isVisible;
});

const confirm = () => {
  emit("confirm");
};

const cancel = () => {
  emit("cancel");
};
</script>

<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50"
  >
    <div class="bg-white p-6 rounded shadow-md">
      <h1 class="text-4xl mb-5" v-if="title">{{ title }}</h1>
      <p class="mb-4">{{ message }}</p>
      <slot></slot>
      <div class="flex justify-end space-x-4">
        <button
          v-if="!hideCancel"
          @click="cancel"
          class="px-4 py-2 rounded bg-gray-300 hover:bg-gray-400"
        >
          {{ cancelButtonText }}
        </button>
        <button
          @click="confirm"
          class="px-4 py-2 rounded text-white disabled:opacity-10 disabled:cursor-not-allowed"
          :class="confirmButtonClass"
          :disabled="!isValid"
        >
          {{ confirmButtonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<style></style>

<script setup lang="ts"></script>

<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-100">
    <div class="max-w-md w-full p-6 bg-white rounded-md shadow-md">
      <h2 class="text-3xl font-semibold text-indigo-600 mb-6">Oopsie Daisy!</h2>
      <p class="text-gray-800 mb-4">
        Looks like the secret handshake didn't quite work out. Don't worry, we
        won't tell anyone. 😜
      </p>
      <p class="text-gray-800 mb-4">
        Just hit the button to get authenticated again
      </p>
      <router-link
        to="/"
        class="bg-indigo-500 text-white py-2 px-4 rounded-full hover:bg-indigo-700 transition"
      >
        Retry the Secret Handshake
      </router-link>
    </div>
  </div>
</template>

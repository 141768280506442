<script setup lang="ts">
import { DeploymentStatus } from "@/api/models";
import { computed } from "vue";
import { formatDeploymentStatus } from "@/common/utils";

const deploymentStatus = DeploymentStatus;
const isSpinnerVisible = computed(() => {
  return (
    props.deploymentRequest.status == DeploymentStatus.PendingDeployment ||
    props.deploymentRequest.status == DeploymentStatus.Deployed ||
    props.deploymentRequest.status == DeploymentStatus.Deploying ||
    props.deploymentRequest.status == DeploymentStatus.PendingDestroy ||
    props.deploymentRequest.status == DeploymentStatus.Destroying ||
    props.deploymentRequest.status == DeploymentStatus.PendingDatabaseReset ||
    props.deploymentRequest.status == DeploymentStatus.DatabaseResetting
  );
});
const props = defineProps({
  deploymentRequest: {
    type: Object,
    required: true,
  },
  showText: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div
    class="flex items-center"
    :title="formatDeploymentStatus(deploymentRequest.status)"
  >
    <div
      v-if="isSpinnerVisible"
      class="loader mr-2"
      :class="{
        'pending-deployment':
          props.deploymentRequest.status == deploymentStatus.PendingDeployment,
        deploying: props.deploymentRequest.status == deploymentStatus.Deploying,
        deployed: props.deploymentRequest.status == deploymentStatus.Deployed,
        'pending-destroy':
          props.deploymentRequest.status == deploymentStatus.PendingDestroy,
        destroying:
          props.deploymentRequest.status == deploymentStatus.Destroying,
        'pending-database-reset':
          props.deploymentRequest.status ==
          deploymentStatus.PendingDatabaseReset,
        'resetting-database':
          props.deploymentRequest.status == deploymentStatus.DatabaseResetting,
      }"
    ></div>
    <div v-if="props.showText">
      <div>{{ formatDeploymentStatus(deploymentRequest.status) }}</div>
      <span
        class="text-red-400 text-xs"
        v-if="deploymentRequest.status == deploymentStatus.Error"
        >{{ deploymentRequest.errorMessage }}</span
      >
    </div>
  </div>
</template>

<style>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 20px #3498db;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 35px #f39c12, 0 0 15px #3498db;
    transform: scale(1.3);
  }

  100% {
    box-shadow: 0 0 35px #f39c12, 0 0 15px #3498db;
    transform: scale(1);
  }
}

/* Style for the Loader */
.loader {
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

.loader.pending-deployment {
  width: 12px;
  height: 12px;
  background-color: #f39c12;
  border: #f39c12;
  animation: pulsate 3s linear infinite;
}

.loader.pending-database-reset {
  width: 12px;
  height: 12px;
  background-color: #0fb8f6;
  border: #0fb8f6;
  animation: pulsate 3s linear infinite;
}

.loader.deploying {
  border-top-color: green;
}

.loader.deployed {
  background-color: green;
  border: green;
  width: 12px;
  height: 12px;
}
.loader.pending-destroy {
  background-color: red;
  border: red;
  width: 12px;
  height: 12px;
  animation: pulsate 3s linear infinite;
}
.loader.destroying {
  border-top-color: red;
}

.loader.resetting-database {
  border-top-color: #0fb8f6;
}

.dot {
  transition: all 0.3s ease-in-out;
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SignInView from "../views/SignInView.vue";
import SignInFailedView from "../views/SignInFailedView.vue";
import auth from "@/services/Auth";
const unmatched = "/:pathMatch(.*)*";

const unguarded = ["/login", "/logout"];
const routeNames = {
  home: "home",
  signIn: "sign-in",
  signInFailed: "sign-in-failed",
};
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: routeNames.home,
      component: HomeView,
    },
    {
      path: "/sign-in",
      name: routeNames.signIn,
      component: SignInView,
    },
    {
      path: "/sign-in-failed",
      name: routeNames.signInFailed,
      component: SignInFailedView,
    },
  ],
});
router.beforeEach(async (to, from, next) => {
  // 404
  if (to.matched[0]?.path === unmatched || to.name == routeNames.signInFailed) {
    return next();
  }

  // guarded
  const guarded = unguarded.every((path) => path !== to.path);
  if (guarded) {
    // initialized
    if (!auth.initialized) {
      await auth.initialize();
    }

    // authorised
    if (auth.account) {
      return next();
    }

    // unauthorised
    try {
      await auth.login();
      return next();
    } catch (err) {
      return next(false);
    }
  }

  // unguarded
  next();
});
export { routeNames };
export default router;

import * as signalR from "@microsoft/signalr";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import Auth from "./Auth";

export default class SignalRHubConnection {
  protected connection: HubConnection;

  private _hubName: string;
  private _connectionStateChangedListeners = [];

  constructor(hubName: string) {
    this._hubName = hubName;
    this.connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(import.meta.env.VITE_API_BASE_URL + `/hubs/${this._hubName}`, {
        accessTokenFactory: () => {
          return Auth.accessToken;
        },
      })
      .build();
  }

  public async init(): Promise<void> {
    this.connection.onreconnecting((err) => {
      this.onConnectionStateChanged(this.connection.state);
    });

    this.connection.onreconnected(() => {
      this.onConnectionStateChanged(this.connection.state);
    });

    await this.connection.start();
    this.onConnectionStateChanged(this.connection.state);
  }

  public addConnectionChangedListener(
    callback: (state: HubConnectionState) => void
  ): void {
    this._connectionStateChangedListeners.push(callback);
  }

  public removeConnectionChangedListener(
    callback: (state: HubConnectionState) => void
  ): void {
    const index = this._connectionStateChangedListeners.indexOf(callback);
    if (index < 0) {
      return;
    }
    this._connectionStateChangedListeners.splice(index);
  }

  private onConnectionStateChanged(state: HubConnectionState): void {
    if (this._connectionStateChangedListeners.length <= 0) {
      return;
    }
    for (const listener of this._connectionStateChangedListeners) {
      listener(state);
    }
  }
}

import {
  PublicClientApplication,
  type AuthenticationResult,
  NavigationClient,
  type AccountInfo,
} from "@azure/msal-browser";
import router from "@/router/";
export type MaybeAccount = AccountInfo | null;
const config = {
  auth: {
    clientId: "eadb4f43-df38-440b-8d27-0f8451689490",
    redirectUri: window.location.origin + "/sign-in",
    postLogoutRedirectUri: "/logout",
  },
};
const refreshTokenTimeoutDurationMs = 60000 * 15;
export const msal = new PublicClientApplication(config);
await msal.initialize();
class Auth {
  initialized: boolean = false;
  account: MaybeAccount = null;
  isAuthenticated: boolean = false;
  accessToken: string = "";
  refreshTokenTimer: NodeJS.Timeout;

  async login(): Promise<void> {
    await msal.loginRedirect({
      scopes: ["api://eadb4f43-df38-440b-8d27-0f8451689490/myapi"],
    });
  }
  async initialize(): Promise<MaybeAccount> {
    try {
      // start msal
      await msal.handleRedirectPromise();

      // grab and set account if in session
      const accounts = msal.getAllAccounts();
      if (accounts?.length) {
        this.setAccount(accounts[0]);
        await this.acquireToken();
        this.startRefreshTokenTimeout();
      }

      // return any active account
      return msal.getActiveAccount();
    } catch (ex) {
      console.error(ex);
      router.replace("sign-in-failed");
    }
  }

  setAccount(account: MaybeAccount): void {
    msal.setActiveAccount(account);
    this.account = account;
    this.isAuthenticated = true;
  }

  private startRefreshTokenTimeout(): void {
    this.stopRefreshTokenTimeout();
    this.refreshTokenTimer = setTimeout(() => {
      this.acquireToken();
      this.startRefreshTokenTimeout();
    }, refreshTokenTimeoutDurationMs);
  }

  private stopRefreshTokenTimeout(): void {
    if (!this.refreshTokenTimer) {
      return;
    }

    clearTimeout(this.refreshTokenTimer);
  }

  async acquireToken(): Promise<void> {
    try {
      const apiTokenResponse = await msal.acquireTokenSilent({
        account: this.account as AccountInfo,
        scopes: ["api://eadb4f43-df38-440b-8d27-0f8451689490/myapi"],
      });
      this.accessToken = apiTokenResponse.accessToken;
    } catch (error) {
      // If silent token acquisition fails, you may need to do an interactive login
      console.error("Silent token acquisition failed:", error);
      // You can handle the error and trigger an interactive login here if needed
    }
  }
}
const auth = new Auth();

export default auth;

import axios, {
    AxiosResponse
} from 'axios';
import {
    GetDatabaseTemplatesResponseDto,
    WrappedResponse
} from './models'
export default class DatabaseTemplatesApiService {
    public static getAll(clientConfig ? : {
        ignoreError ? : boolean
    }): Promise < AxiosResponse < WrappedResponse < GetDatabaseTemplatesResponseDto >>> {
        return axios.request( < any > {
            url: `/api/DatabaseTemplates/GetAll`,
            method: 'get',
            clientConfig: clientConfig
        })
    }
}
<script setup lang="ts">
import ConfirmModal from "@/components/ConfirmModal.vue";
import { DeploymentRequestDto, DeploymentStatus } from "@/api/models";
import { computed, ref, toRefs } from "vue";

const props = defineProps({
  deploymentRequest: Object,
  isBusy: Boolean,
});

const emits = defineEmits([
  "run-build",
  "destroy",
  "run-automation-tests",
  "restart-web-app",
  "reset-database",
]);
const { deploymentRequest } = toRefs(props);

const isRequestingBuild = ref<boolean>(false);
const isRequestingTestRun = ref<boolean>(false);
const showRestartWebAppModal = ref<boolean>(false);
const showDestroyModal = ref<boolean>(false);
const showResetDatabaseModal = ref<boolean>(false);
const showRunAutomationTestsModal = ref<boolean>(false);

const canDestroyDeployment = computed(() => {
  return deploymentRequest.value.status == DeploymentStatus.Deployed;
});

const canRunBuild = computed(() => {
  return (
    deploymentRequest.value.branchName != null &&
    !deploymentRequest.value.destroyRequestedDate
  );
});

const canRunAutomationTests = computed(() => {
  return deploymentRequest.value.status == DeploymentStatus.Deployed;
});

const canResetDatabase = computed(() => {
  return deploymentRequest.value.status == DeploymentStatus.Deployed;
});

function runBuild(): void {
  emits("run-build", deploymentRequest.value);
}

function confirmDestroy(): void {
  emits("destroy", deploymentRequest.value);
  showDestroyModal.value = false;
}
function cancelDestroy() {
  showDestroyModal.value = false;
}

function confirmRestartWebApp(): void {
  emits("restart-web-app", deploymentRequest.value);
  showRestartWebAppModal.value = false;
}
function cancelRestartWebApp(): void {
  showRestartWebAppModal.value = false;
}

function confirmDatabaseReset(): void {
  emits("reset-database", deploymentRequest.value);
  showResetDatabaseModal.value = false;
}
function cancelDatabaseReset(): void {
  showResetDatabaseModal.value = false;
}

function confirmRunAutomationTests(): void {
  emits("run-automation-tests", deploymentRequest.value);
  showRunAutomationTestsModal.value = false;
}

function cancelRunAutomationTests(): void {
  showRunAutomationTestsModal.value = false;
}
</script>

<template>
  <ConfirmModal
    @confirm="confirmDestroy"
    @cancel="cancelDestroy"
    :is-visible="showDestroyModal"
    message="Are you sure you want to delete this deployment? All data will be lost"
    modal-type="Error"
  />

  <ConfirmModal
    @confirm="confirmRestartWebApp"
    @cancel="cancelRestartWebApp"
    message="Are you sure you want to restart this web app? This will load the latest build."
    :is-visible="showRestartWebAppModal"
  />

  <ConfirmModal
    @confirm="confirmDatabaseReset"
    @cancel="cancelDatabaseReset"
    message="Are you sure? The state of the database will be reset. New data since deployment will be lost."
    :is-visible="showResetDatabaseModal"
  />

  <ConfirmModal
    message="Are you sure? This will create a new environment and run the tests on a known database. This can take 25+ minutes"
    :is-visible="showRunAutomationTestsModal"
    @confirm="confirmRunAutomationTests"
    @cancel="cancelRunAutomationTests"
  >
  </ConfirmModal>

  <div class="flex">
    <button
      class="ml-2 w-4 cursor-pointer first-letter: disabled:opacity-10 disabled:cursor-not-allowed"
      v-if="canRunBuild"
      @click="runBuild()"
      :disabled="isBusy || isRequestingBuild"
      title="Request branch build"
    >
      <img width="18" height="18" src="/images/build.svg" />
    </button>

    <button
      class="ml-2 w-4 cursor-pointer first-letter: disabled:opacity-10 disabled:cursor-not-allowed"
      v-if="canDestroyDeployment"
      @click="showRestartWebAppModal = true"
      :disabled="isBusy"
      title="Reload"
    >
      <img width="18" height="18" src="/images/reload.svg" />
    </button>

    <button
      class="ml-2 w-4 cursor-pointer first-letter: disabled:opacity-10 disabled:cursor-not-allowed"
      v-if="canRunAutomationTests"
      @click="showRunAutomationTestsModal = true"
      :disabled="isBusy || isRequestingTestRun"
      title="Run automation tests"
    >
      <img width="18" height="18" src="/images/test.svg" />
    </button>

    <button
      class="ml-3 w-5 cursor-pointer first-letter: disabled:opacity-10 disabled:cursor-not-allowed"
      :disabled="isBusy || isRequestingTestRun"
      title="Reset database"
      @click="showResetDatabaseModal = true"
      v-if="canResetDatabase"
    >
      <img width="24" height="24" src="/images/resetdb.png" />
    </button>

    <button
      class="ml-2 w-5 cursor-pointer first-letter: disabled:opacity-10 disabled:cursor-not-allowed"
      v-if="canDestroyDeployment"
      :disabled="isBusy"
      @click="showDestroyModal = true"
      title="Destroy"
    >
      <img width="24" height="24" src="/images/delete.svg" />
    </button>
  </div>
</template>

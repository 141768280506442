import { DeploymentRequestLogDto } from "@/api/models";
import SignalRHubConnection from "./SignalRHubConnection";

export class DeploymentRequestLogsHubConnection extends SignalRHubConnection {
  constructor() {
    super("deployment-request-logs");
  }

  private _deploymentRequestedLogInsertedMethodName =
    "deployment-request-log-inserted";

  public subscribeToDeploymentRequestLogs(
    deploymentRequestId: number,
    callBack: (log: DeploymentRequestLogDto) => void
  ): void {
    this.connection.invoke(
      "SubscribeToDeploymentRequestLogs",
      deploymentRequestId
    );
    this.connection.on(
      this._deploymentRequestedLogInsertedMethodName,
      callBack
    );
  }

  public unsubscribeToDeploymentRequestLogs(
    deploymentRequestId: number,
    callBack: (log: DeploymentRequestLogDto) => void
  ): void {
    this.connection.invoke(
      "UnsubscribeToDeploymentRequestLogs",
      deploymentRequestId
    );
    this.connection.off(
      this._deploymentRequestedLogInsertedMethodName,
      callBack
    );
  }
}

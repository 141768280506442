import "./index.css";
import axios from "axios";
import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import Auth from "./services/Auth";
axios.defaults.baseURL = import.meta.env.VITE_API_BASE_URL;

axios.interceptors.request.use(
  async (request) => {
    if (Auth.isAuthenticated) {
      const accessToken = await Auth.accessToken;
      request.headers["Authorization"] = "Bearer " + accessToken;
    }
    return request;
  },
  (error) => {
    console.error(error);
  }
);

axios.interceptors.response.use((response) => {
  if ([401, 403].includes(response.status)) {
    window.location.reload();
  }
  return response;
});

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount("#app");

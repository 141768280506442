<script setup lang="ts">
import router from "@/router";
import auth from "@/services/Auth";

if (auth.isAuthenticated) {
  router.push("/");
}
</script>

<template>
  <div>Authenticating...</div>
</template>

<script setup lang="ts">
import { AutomationTestJobStatus } from "@/api/models";
import { computed } from "vue";
import { formatAutomationTestJobStatus } from "@/common/utils";

const automationTestJobStatus = AutomationTestJobStatus;
const isSpinnerVisible = computed(() => {
  return (
    props.automationTestJob.status == AutomationTestJobStatus.Queued ||
    props.automationTestJob.status ==
      AutomationTestJobStatus.WaitingForDeployment ||
    props.automationTestJob.status == AutomationTestJobStatus.RunningTests ||
    props.automationTestJob.status ==
      AutomationTestJobStatus.DestroyingDeployment
  );
});
const props = defineProps({
  automationTestJob: {
    type: Object,
    required: true,
  },
  showText: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div
    class="flex items-center"
    :title="formatAutomationTestJobStatus(automationTestJob.status)"
  >
    <div
      v-if="isSpinnerVisible"
      class="loader mr-2"
      :class="{
        queued:
          props.automationTestJob.status == automationTestJobStatus.Queued,
        'waiting-for-deployment':
          props.automationTestJob.status ==
          automationTestJobStatus.WaitingForDeployment,
        'running-tests':
          props.automationTestJob.status ==
          automationTestJobStatus.RunningTests,

        destroying:
          props.automationTestJob.status ==
          automationTestJobStatus.DestroyingDeployment,
      }"
    ></div>

    <div v-if="props.showText">
      <div>{{ formatAutomationTestJobStatus(automationTestJob.status) }}</div>
      <span
        class="text-red-400 text-xs"
        v-if="automationTestJob.status == automationTestJobStatus.Error"
        >{{ automationTestJob.errorMessage }}</span
      >
    </div>
  </div>
</template>

<style>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    box-shadow: 0 0 20px #3498db;
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 35px #f39c12, 0 0 15px #3498db;
    transform: scale(1.3);
  }

  100% {
    box-shadow: 0 0 35px #f39c12, 0 0 15px #3498db;
    transform: scale(1);
  }
}

/* Style for the Loader */
.loader {
  border: 4px solid #f3f3f3; /* Light grey background */
  border-top: 4px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

.loader.queued {
  width: 12px;
  height: 12px;
  background-color: #f39c12;
  border: #f39c12;
  animation: pulsate 3s linear infinite;
}

.loader.pending-database-reset {
  width: 12px;
  height: 12px;
  background-color: #0fb8f6;
  border: #0fb8f6;
  animation: pulsate 3s linear infinite;
}

.loader.waiting-for-deployment {
  border-top-color: green;
}

.loader.deployed {
  background-color: green;
  border: green;
  width: 12px;
  height: 12px;
}

.loader.destroying {
  border-top-color: red;
}

.dot {
  transition: all 0.3s ease-in-out;
}
</style>
